<template>
  <div class="ss-header">
    <div class="ss-header-content" :class="{ 'custom-bg-color': isMniAccount }">
      <div :class="isMniAccount ? 'costom-mni-header' : ''">
        <div v-if="showTitle" class="pg-title" :class="{ 'custom-bg-title': isMniAccount }">
          {{ title }}
        </div>
        <div id="container-account-user" class="ss-right">
          <switch-account
            :show-full-view="showFullView"
            :show-accounts="showAccounts"
            :is-old-flip-header="true"
            :class="isLeftNavCollapsed ? '' : 'flexi-dt-cont'"
            @toggle-account-switcher="toggleAccountSwitcher"
          />
          <!-- <span v-if="!isMniAccount" class="mp-nav-acct-name" @click="showAccounts = !showAccounts"> -->
          <span class="mp-nav-acct-name" @click="showAccounts = !showAccounts">
            <font-awesome-icon
              :icon="['far', 'user']"
              class="navpencil"
              :style="isMniAccount ? { color: 'white' } : ''"
              size="3x"
            />
          </span>
        </div>
        <div v-if="showTopNavigation" class="ss-nav">
          <div
            v-if="hasPixelTabAccess()"
            class="nav-item hicon"
            :class="selectedMenuOption === LAYOUT_TYPES.FLIPPIXEL ? 'active' : ''"
            @click="changeSelectedMenuOption(LAYOUT_TYPES.FLIPPIXEL)"
          >
            <font-awesome-icon :icon="['fal', 'code']" class="inline-fa" />
            <div class="ss-icon-txt">Pixel</div>
          </div>
          <div
            v-if="hasNewCampaignAccess()"
            class="nav-item hicon"
            :class="selectedMenuOption === LAYOUT_TYPES.NEW_CAMPAIGNS ? 'active' : ''"
            @click="addNewCampaignButtonClick()"
          >
            <font-awesome-icon :icon="['fal', 'plus']" class="inline-fa" />
            <div class="ss-icon-txt twolines">New Campaign</div>
          </div>
          <div
            v-if="hasCampaignManagerAccess()"
            class="nav-item hicon"
            :class="selectedMenuOption === LAYOUT_TYPES.CAMPAIGNS ? 'active' : ''"
            @click="changeSelectedMenuOption(LAYOUT_TYPES.CAMPAIGNS)"
          >
            <font-awesome-icon :icon="['fal', 'folder-open']" class="inline-fa" />
            <div class="ss-icon-txt">Campaigns</div>
          </div>
          <div
            v-if="hasReportingDashboardAccess()"
            class="nav-item hicon"
            :class="selectedMenuOption === LAYOUT_TYPES.DASHBOARD ? 'active' : ''"
            @click="changeSelectedMenuOption(LAYOUT_TYPES.DASHBOARD)"
          >
            <font-awesome-icon :icon="['fal', 'chart-line-up']" class="inline-fa" />
            <div class="ss-icon-txt">Reporting</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { isEqual } from 'lodash';
import { isBlank } from 'adready-api/helpers/common';
import _ from 'underscore';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import accountApi from 'adready-api/api/account';
import { LAYOUT_TYPES } from '~/constant';
import appConsoleDataLayer from '~/api/app-console-data';
import config from '~/config';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import {
  isDemoInstance,
  isFlamingoInstance,
  filterAccountsAndAdvertisersForDemo,
} from '~/util/utility-functions';
import campaignMixin from '~/components/mixins/campaign-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import { getQueryParams } from '~/helpers/global/url-helpers';

export default {
  components: {
    SwitchAccount: () =>
      import(/* webpackChunkName: "b-dropdown-select-account" */ '~/components/switch-account.vue'),
  },
  mixins: [gaEventsMixin, campaignMixin, authzMixin],
  props: {
    showTopNavigation: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    isLeftNavCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDemoInstance,
      isFlamingoInstance,
      LAYOUT_TYPES,
      showAccounts: false,
      loading: false,
      showFullView: true,
    };
  },
  computed: {
    userAccounts: get('common/userAccounts'),
    selectedMenuOption: get('common/selectedMenuOption'),
    advName: get('common/demoSelectedAdvertiserName'),
    activeItem: sync('common/activeItem'),
    isMniAccount: get('common/isMniAccount'),
    theUser: get('common/currentUser'),
    theAccount: get('common/account'),
    demoAdvertiserMappings: get('common/demoAdvertiserMappings'),

    logoFileId() {
      if (!this.account || !this.account?.organization?.whitelabelEnabled) {
        return null;
      }

      return this.account?.organization?.logoFile?.id || null;
    },
    isSelfServeAdvertiser() {
      return this.advertiser?.selfServe;
    },
    userAccountsFiltered() {
      const accountKeys = this.demoAdvertiserMappings.map((obj) => parseInt(obj.accountId, 10));
      const advertiserKeys = this.demoAdvertiserMappings.map((obj) =>
        parseInt(obj.advertiserId, 10)
      );
      return filterAccountsAndAdvertisersForDemo(
        this.userAccounts.filter((ua) => ua.name),
        accountKeys,
        advertiserKeys
      );
    },
    isMoreThanOneAdvertiser() {
      if (this.userAccountsFiltered?.length > 1) {
        return true;
      }
      if (this.userAccountsFiltered[0]?.advertisers?.length > 1) {
        return true;
      }
      return false;
    },
  },
  watch: {
    account(account) {
      const isSelfServe =
        account?.advertisers?.length === 1 ? account?.advertisers[0]?.selfServe : false;
      if (!isSelfServe && account?.organization?.whitelabelEnabled) {
        this.$flipTheme?.setTheme(account?.organization?.theme);
      } else {
        this.$flipTheme?.setDefaultTheme();
      }
    },
    advertiser: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue) && !newValue?.isAdvUpdatedInAccSetup && newValue) {
          // this.changeSelectedMenuOption(this.handleLandingPage(newValue));
          const selectedMenuPage = localStorage.getItem('FLIP_SELECTED_MENU_PAGE');
          if (selectedMenuPage && selectedMenuPage === LAYOUT_TYPES.FLIPPIXEL) {
            this.changeSelectedMenuOption(LAYOUT_TYPES.FLIPPIXEL);
          } else {
            this.changeSelectedMenuOption(LAYOUT_TYPES.DASHBOARD);
          }
        }
      },
    },
  },

  created() {
    this.$on('on-edit-item', this.onEditItem);
    this.$on('on-change-selected-menu-option', this.changeSelectedMenuOption);
  },
  beforeDestroy() {
    this.$off('on-edit-item', this.onEditItem);
    this.$off('on-change-selected-menu-option', this.changeSelectedMenuOption);
  },
  methods: {
    toggleAccountSwitcher(val) {
      this.showAccounts = val;
    },
    isFlipPlus() {
      return this.mode && this.mode === 'fp';
    },
    getDefaultLandingPageForSelfServe(adv) {
      const { accountSetup = {}, pixelSetup = {} } = adv || {};
      const accountSetupComplete =
        accountSetup?.accountInfoAvailable && accountSetup?.paymentInfoAvailable;
      const pixelSetupComplete = pixelSetup?.pixelAdded && pixelSetup?.pixelVerified;
      const selectedPage =
        accountSetupComplete && pixelSetupComplete
          ? LAYOUT_TYPES.CAMPAIGNS
          : LAYOUT_TYPES.ACCOUNT_SETUP;

      return selectedPage;
    },
    getRoleHasAccessToPage(role) {
      const pages = [];
      if (role === 'INTERNAL_USER') {
        pages.push(LAYOUT_TYPES.DASHBOARD);
      } else if (
        role === 'EXTERNAL_USER' ||
        role === 'EXTERNAL_SUB_ADMIN' ||
        role === 'INTERNAL_SUB_ADMIN' ||
        role === 'INTERNAL_ADMIN'
      ) {
        pages.push(LAYOUT_TYPES.DASHBOARD);
        // pages.push(LAYOUT_TYPES.CAMPAIGNS);
        // pages.push(LAYOUT_TYPES.NEW_CAMPAIGNS);
        // pages.push(LAYOUT_TYPES.ACCOUNT_SETUP);
      }
      return pages;
    },
    onEditItem(activeLi) {
      this.activeItem = activeLi;
    },
    hasPixelTabAccess() {
      if (isFlamingoInstance()) {
        return false;
      }
      return !this.advertiser?.selfServe;
    },
    hasNewCampaignAccess() {
      // if (isFlamingoInstance()) {
      //   return true;
      // }
      // return this.isInternalAdmin;
      return false;
    },
    hasCampaignManagerAccess() {
      // if (isFlamingoInstance()) {
      //   return false;
      // }
      // return this.isInternalAdmin;
      return false;
    },
    hasReportingDashboardAccess() {
      // return this.hasNewCampaignAccess || this.hasCampaignManagerAccess || this.hasPixelTabAccess;
      return true;
    },
    hideAccountSwitchers() {
      this.showAccounts = false;
    },
    // handleLandingPage(adv) {
    //   const { selfServe = false } = adv || {};
    //   // 1 For non-selfServe always return dashboard page.
    //   if (!selfServe) {
    //     localStorage.setItem(FLIP_SELECTED_MENU_PAGE, LAYOUT_TYPES.DASHBOARD);
    //     return LAYOUT_TYPES.DASHBOARD;
    //   }
    //   let landingPage = '';
    //   const lasVisitedPage = localStorage.getItem(FLIP_SELECTED_MENU_PAGE);
    //   // 2.1 if last visited page is empty
    //   if (!lasVisitedPage) {
    //     landingPage = this.getDefaultLandingPageForSelfServe(adv);
    //   } else {
    //     // 2.2.1 Get the list of allowed pages for logged user's role
    //     const pageList = this.getRoleHasAccessToPage(this.currentUserRole);
    //     // 2.2.2 if last visited page exists in the 2.2.1 list
    //     if (pageList.includes(lasVisitedPage)) {
    //       if (lasVisitedPage === LAYOUT_TYPES.NEW_CAMPAIGNS) {
    //         this.addNewCampaignButtonClick();
    //       }
    //       landingPage = lasVisitedPage;
    //     } else {
    //       landingPage = this.getDefaultLandingPageForSelfServe(adv);
    //     }
    //   }
    //   localStorage.setItem(FLIP_SELECTED_MENU_PAGE, landingPage);
    //   return landingPage;
    // },
    getAdvertisersList() {
      if (this.account) {
        const { advertisers } = this.account;
        if (this.account && !isBlank(advertisers)) {
          return _.sortBy(advertisers, (a) => a.name.toLowerCase());
        }
      }
      return [];
    },
    async onSelectAccount(advertiser, demoAdvertiser) {
      if (isDemoInstance()) {
        const theUserId = this.theUser && this.theUser.id ? this.theUser.id : '';
        accountApi.userAccount(theUserId).then((res) => {
          const accountKeys = this.demoAdvertiserMappings.map((obj) => parseInt(obj.accountId, 10));
          const advertiserKeys = this.demoAdvertiserMappings.map((obj) =>
            parseInt(obj.advertiserId, 10)
          );
          const demoAccounts = filterAccountsAndAdvertisersForDemo(
            res,
            accountKeys,
            advertiserKeys
          );
          let demoAccount = null;
          Object.values(demoAccounts).some((account) => {
            if (account && account.advertisers) {
              return Object.values(account.advertisers).some((adv) => {
                if (adv && adv.name === demoAdvertiser?.name) {
                  demoAccount = account;
                  return true;
                }
                return false;
              });
            }
            return false;
          });
          config.ziplyneChangeAdvertiser(
            this.theUser?.email,
            demoAdvertiser?.name,
            demoAccount?.name
          );
        });
      } else {
        config.ziplyneChangeAdvertiser(
          this.theUser?.email,
          advertiser?.name,
          this.theAccount?.name
        );
      }

      EventBus.$emit('toggle-spinner', true);
      this.loading = true;
      if (!isDemoInstance()) {
        const { name, id } = this.advertiser;
        if (advertiser.name === name && advertiser.id === id) {
          EventBus.$emit('toggle-spinner', false);
          return;
        }
      }
      const params = getQueryParams(window.location.href);
      if (params?.embed === 'true' && window.parent) {
        const reqAdvertiser = isDemoInstance() ? demoAdvertiser : advertiser;
        window.parent.postMessage(
          { type: 'embedAdvertiserSwitch', advertiser: reqAdvertiser },
          '*'
        );
        EventBus.$emit('toggle-spinner', false);
        return;
      }
      try {
        if (advertiser.id) {
          await this.$store.dispatch('common/switchAdvertiser', {
            appConsoleDataLayer,
            advertiserId: advertiser.id,
          });
          this.$emit('on-select-advertiser', advertiser);
          EventBus.$emit('toggle-spinner', false);
        }
      } catch (err) {
        console.error('error switching account on dashboard ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        this.universalPixelId = null;
        EventBus.$emit('toggle-spinner', false);
      } finally {
        this.loading = false;
        EventBus.$emit('toggle-spinner', false);
      }
    },
    fireTopLevelMenuSelection(option) {
      if (config.gaEnabled()) {
        const gaEv = {
          item: option,
        };
        this.fireGAEvent('flp_tlpu_selection', gaEv, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-board {
  margin-top: 60px;
  text-align: center;
  background-color: #ffffff;
  height: 100vh;
  position: relative;
}
// new header
.hicon {
  position: relative;
  display: inline-block;
  width: 56px;
  padding: 20px 0px;
  margin: 0px;
  font-size: 18px;
  color: #a1a5af !important;
  text-align: center;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  &:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
  &.active {
    color: #fff !important;
  }
  &:hover {
    top: -6px;
    height: 66px;
    color: #fff;
  }
}
.hicon.active::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 2px;
  content: '';
  background-color: var(--primarycolor);
}
.nav-item:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.2);

  .ss-icon-txt {
    margin-top: 26px;
    opacity: 1;
    &.twolines {
      margin-top: 21px;
    }
  }
}
.ss-icon-txt {
  position: absolute;
  left: 50%;
  display: inline-block;
  width: 60px;
  margin-top: 10px;
  margin-left: -30px;
  font-size: 10px;
  line-height: 1.1em;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.light-theme {
  .ss-header {
    border-bottom: 1px solid #ddd;
    background-color: #fbfcfe;
    .ss-header-content {
    }
  }
  .hicon {
    position: relative;
    display: inline-block;
    width: 56px;
    padding: 20px 0px;
    margin: 0px;
    font-size: 18px;
    color: #444 !important;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    &:last-child {
      border-right: 1px solid rgba(255, 255, 255, 0.05);
    }
    &.active {
      color: #222 !important;
    }
    &:hover {
      top: -6px;
      height: 66px;
      background-color: rgba(0, 0, 0, 0.06) !important;
    }
  }
  .hicon.active::after {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: block;
    width: 100%;
    height: 2px;
    content: '';
    background-color: var(--primarycolor);
  }
  .navpencil:hover {
    color: #464646;
  }
}
.custom-bg-color {
  background-color: #696c7e;
}
.custom-bg-title {
  color: #ffffff !important;
  font-size: 22px !important;
  font-family: 'Manrope', sans-serif !important;
}
.ss-header {
  position: fixed;
  top: 0px;
  left: 4px;
  z-index: 9999;
  width: 100%;
  height: 55px;
  background-color: #1b1d21;
  .ss-header-content {
    display: block;
    margin: auto;
    .ss-logo {
      display: inline-block;
      width: 80px;
      margin-top: 12px;
      margin-left: 40px;
      .ss-logo-img {
        width: auto;
        img {
          width: auto;
          height: 30px;
        }
      }
    }

    ::v-deep .ss-right {
      display: flex;
      float: right;
      margin-top: 3px;
      margin-right: 33px;
      .account-popup {
        // top: 65px !important;
        // right: 32px !important;
        // z-index: 12 !important;
        // background-color: #2c3035 !important;
        // border: none !important;
        .account-container {
          width: 250px;
          max-height: 308px;
          // background-color: #2c3035 !important;
          .form-input-field {
            width: 100% !important;
            height: 35px;
            padding: 10px !important;
            margin-top: 4px !important;
            color: #000 !important;
            background-color: #fff !important;
            // border: 1px solid #2c3035 !important;
            border-radius: 3px;
          }
        }
      }
      .mp-nav-acct-name {
        font-size: 12px;
        font-weight: 400;
        color: #a1a5af;
        cursor: pointer;
        .navpencil {
          height: 60px;
          margin-top: -5px;
          font-size: 19px;
          color: #797c8a;
          cursor: pointer;
          width: 0.875em;
        }
      }
    }

    .ss-nav {
      display: inline-block;
      float: right;
      margin-top: -5px;

      .nav-item {
        display: inline-block;
        color: white;
        cursor: pointer;
      }
    }
  }
  ::v-deep .logo img {
    width: auto !important;
    height: 35px !important;
  }
}
.light-theme {
  .ss-header {
    .ss-header-content {
      .ss-logo {
        .ss-logo-img {
          display: none;
        }
      }
      .pg-title {
        font-family: 'Manrope', sans-serif;
        font-size: 19px;
        color: rgba(0, 0, 0, 0.87);
        display: inline-flex;
        width: 400px;
        margin-left: 100px;
        height: 50px;
        align-items: center;
      }
    }
  }
}
.pg-title {
  font-family: 'Manrope', sans-serif;
  font-size: 19px;
  color: #a1a5af;
  display: inline-flex;
  width: 350px;
  margin-left: 45px;
  height: 60px;
  align-items: center;
}
.costom-mni-header {
  max-width: 1300px;
  margin: 0 auto;
  min-height: 72px;
  padding-top: 12px;
}
.filter-details {
  display: inline-block;
  margin-right: 0.2rem;
  cursor: pointer;
  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.2);
  // }

  .filter {
    display: inline-block;
    padding: 0 4px 0 4px;
    margin-right: 8px;
    text-align: left;

    &:last-child {
      margin-left: 10px;
      border-right: 1px dashed #232222;
      div {
        margin-right: 5px;
      }
    }

    div {
      font-size: 12px;

      &.main {
        padding: 2px 0 2px 0;
        font-weight: 500;
        color: #4c5461;
      }

      &.sub {
        font-weight: 600;
        color: #667184;
      }
    }
  }
}
.light-theme {
  .filter-details.lookup {
    span.main {
      span.sub {
        color: #4f535d;
      }
    }
  }
}
.custom-mni-text {
  padding-top: 2px !important;
  color: #fff !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}
.custom-mni-sub-text {
  text-align: right !important;
  display: block;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff66;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  padding: 0px;
  margin-top: -4px;
}
.filter-details.lookup {
  position: relative;
  display: inline-block;
  padding: 8px;
  color: #717482;
  vertical-align: text-bottom;
  cursor: pointer;
  span.main {
    font-size: 11px;
    font-weight: 300;
    color: #717482;
    span.sub {
      display: inline-block;
      min-width: 70px;
      max-width: 125px;
      margin-top: -12px;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
      color: #a1a5af;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
      cursor: pointer;
      font-family: 'Manrope', sans-serif !important;
    }
    svg {
      position: relative;
      display: inline-flex;
      align-items: center;
      font-size: 10px;
      color: #ccc;
    }
  }
}
.sclosed .tfb-content-wrap {
  padding: 0 31px 0 37px;
}
.sclosed .filter-and-date-tabs {
  transition: all 0.3s ease;
  padding-left: 97px;
  margin: auto;
  max-width: 1340px;
  padding-right: 43px;
}
.sclosed .tfb-content-wrap {
  padding-left: 89px !important;
  margin: auto;
  max-width: 1320px !important;
}
</style>
